/* Angular Imports */
import { Component, OnInit }                from '@angular/core';
/* Feature Imports */
import { InstanceService }                  from '../../shared/services/instance.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  host: {
    'class': 'app-toolbar'
  }
})
export class ToolbarComponent implements OnInit {


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private instanceSvc : InstanceService) {

   }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void { 
    this.instanceSvc.instanceMountedAt('ToolbarComponent', 'app-toolbar');
  }

  ////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////

  loadNeedHelpScript() {
    const script = document.createElement('script');
    script.id = "pega-wm-chat";
    script.src = "https://widget.use1.chat.pega.digital/305e82bf-8b6c-4367-8aa3-8aa05dff9919/widget.js";
    document.body.appendChild(script);
  }

  // loadNeedHelpScript() {
  //   if (!document.getElementById('pega-wm-chat')) {
  //     const script = document.createElement('script');
  //     script.id = "pega-wm-chat";
  //     script.src = "https://widget.use1.chat.pega.digital/305e82bf-8b6c-4367-8aa3-8aa05dff9919/widget.js";
  //     script.onload = () => {
  //       console.log('Pega chat widget script loaded successfully.');
  //       // Initialize the widget if needed
  //     };
  //     script.onerror = () => {
  //       console.error('Failed to load the Pega chat widget script.');
  //     };
  //     document.body.appendChild(script);
  //   } else {
  //     console.log('Pega chat widget script is already loaded.');
  //   }
  // }

}